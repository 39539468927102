import { isPlatformBrowser } from '@angular/common';
import { Component, ElementRef, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Link } from '@simpl/element-ng';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EnvironmentSettings } from '../environment-settings';
import { ConfigService } from '../shared/config.service';

@Component({
  selector: 'app-login-mask',
  templateUrl: './login-mask.component.html'
})
export class LoginMaskComponent implements OnInit, OnDestroy {
  customLogin = false;
  loginDisabled = false;
  isBrowser: boolean = isPlatformBrowser(this.platformId);
  links: Link[] = [];
  settings: EnvironmentSettings | undefined;

  userMail$ = new BehaviorSubject('');

  hasCustomLogin = false;

  @ViewChild('email')
  emailInput!: ElementRef;

  @ViewChild('login')
  loginButton!: ElementRef;

  emailControl = new UntypedFormControl('', [
    Validators.required,
    Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
  ]);

  private destroyed$ = new Subject<void>();

  constructor(
    private configService: ConfigService,
    @Inject(PLATFORM_ID) private platformId: any
  ) { }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  async ngOnInit(): Promise<void> {
    if (this.getCustomerParameter() !== '') {
      await this.sendTenantInfoToAuthGateway(this.getCustomerParameter());
      this.goToApplication();
      return;
    }

    this.settings = this.configService.getConfig();
    this.hasCustomLogin = !!this.settings.customIdpConfig?.length;

    this.emailControl.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(val => {
      this.loginDisabled = !this.loginEnabled(val);
    });
  }

  toggleCustomLogin(): void {
    this.customLogin = !this.customLogin;

    if (this.customLogin) {
      this.loginDisabled = !this.loginEnabled(this.emailControl.value);
      setTimeout(() => { this.emailInput.nativeElement.focus(); }, 0);
    } else {
      this.loginDisabled = false;
      setTimeout(() => { this.loginButton.nativeElement.focus(); }, 0);
    }
  }

  private loginEnabled(val: string): boolean {
    if (!this.customLogin) {
      return true;
    }
    const validEmailDomains = this.getValidCustomEmailDomains();
    return validEmailDomains.some(x => val.endsWith(x));
  }
  goToApplication(): void {
    let param = this.getRedirectParameter();
    if (param && !param.startsWith('/') && !param.startsWith(encodeURIComponent('/'))) {
      param = '';
    }

    if (param !== '') {
      localStorage.setItem('redirectUrl', param);
    } else {
      localStorage.removeItem('redirectUrl');
    }

    const redirectUrl = param || this.configService.getConfig().dashboardUrl;
    if (redirectUrl) {
      this._goToApplication(redirectUrl);
    }
  }
  _goToApplication(link: string): void {
    window.location.href = link;
  }
  async onSubmit(): Promise<void> {
    if (!this.isBrowser) {
      return;
    }

    if (this.customLogin) {
      const provider = this.configService.getProviderByEmail(this.emailControl.value);
      if (provider) {
        await this.sendTenantInfoToAuthGateway(provider.tenantId);
      }
    }
    this.goToApplication();
  }

  async sendTenantInfoToAuthGateway(tenantId: string): Promise<void> {
    const config = this.configService.getConfig();
    const tenantInfo = {
      id: tenantId,
      name: 'undefined',
      subdomain: tenantId,
      authUrl: config.authServerUrl.replace('TENANT_ID', tenantId)
    };

    const response = await fetch(config.customLoginUrl, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      mode: 'no-cors',
      body: JSON.stringify(tenantInfo)
    });

    if (!response.ok) {
      console.warn('failed to send tenant info to auth gateway');
    }
  }
  getRedirectParameter(): string {
    const url = new URL(window.location.href);
    return url.searchParams.get('redirectUrl') || '';
  }
  getCustomerParameter(): string {
    const url = new URL(window.location.href);
    return url.searchParams.get('customerId') || '';
  }
  private getValidCustomEmailDomains(): string[] {
    if (!this.settings?.customIdpConfig) {
      return [];
    }
    return this.settings.customIdpConfig.map(y => y.emailDomain);
  }
}
