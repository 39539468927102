import { HttpErrorResponse } from '@angular/common/http';
import {
  ErrorHandler,
  Inject,
  Injectable,
  Injector,
  OnDestroy,
  PLATFORM_ID,
} from '@angular/core';
import { SiErrorHandlerService } from '@sisuite/ui';
import * as Sentry from '@sentry/browser';
import { isPlatformBrowser } from '@angular/common';
import { Subscription } from 'rxjs';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler, OnDestroy {
  private siErrorHandler!: SiErrorHandlerService;
  private isBrowser: boolean;
  private userErrSub$?: Subscription;
  private roleErrSub$?: Subscription;

  constructor(
    private injector: Injector,
    @Inject(PLATFORM_ID) platformId: any
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    setTimeout(() => {
      if (this.isBrowser) {
        this.init();
      }
    });
  }

  init(): void {
    this.siErrorHandler = this.injector.get(SiErrorHandlerService);
  }

  handleError(error: any): void {
    Sentry.captureException(error.originalError || error);
    throw error;
  }

  public handleHttpError(
    error: HttpErrorResponse | string,
    methodName: string
  ): void {
    Sentry.captureException(error);
    if (typeof error === 'string') {
      this.siErrorHandler.showErrorAlert(error);
    } else {
      this.siErrorHandler.handleHttpError(error);
    }
  }

  ngOnDestroy(): void {
    this.userErrSub$?.unsubscribe();
    this.roleErrSub$?.unsubscribe();
  }
}
