<form [formGroup]="requestForm" (ngSubmit)="onSubmit()">
  <p class="card-text">{{ "FORM.REQUEST.TODO_TEXT" | translate }}</p>

  <div class="form-control-has-icon mb-6">
    <input type="text" class="form-control" [class.is-warning]="invalidName" formControlName="companyName" placeholder="{{ 'FORM.REQUEST.COMPANY_NAME' | translate }}">
    <i class="warning-icon element-warning-filled"></i>
    <div class="warning-feedback">{{ "FORM.REQUEST.COMPANY_NAME" | translate }} {{ "FORM.REQUEST.IS_REQUIRED" | translate }}</div>
  </div>

  <div class="form-control-has-icon mb-6">
    <input type="text" class="form-control" [class.is-warning]="invalidStreet" formControlName="street" placeholder="{{ 'FORM.REQUEST.STREET' | translate }}">
    <i class="warning-icon element-warning-filled"></i>
    <div class="warning-feedback">{{ "FORM.REQUEST.STREET" | translate }} {{ "FORM.REQUEST.IS_REQUIRED" | translate }}</div>
  </div>


  <div class="row mb-6">
    <div class="col-md-4">
      <div class="form-control-has-icon">
        <input type="text" class="form-control" [class.is-warning]="invalidPostCode" formControlName="postcode" placeholder="{{ 'FORM.REQUEST.POSTCODE' | translate }}">
        <i class="warning-icon element-warning-filled"></i>
        <div class="warning-feedback">{{ "FORM.REQUEST.POSTCODE" | translate }} {{ "FORM.REQUEST.IS_INVALID" | translate }}</div>
      </div>
    </div>
    <div class="col-md-8">
      <div class="form-control-has-icon">
        <input type="text" class="form-control" [class.is-warning]="invalidCity" formControlName="city" placeholder="{{ 'FORM.REQUEST.CITY' | translate }}">
        <i class="warning-icon element-warning-filled"></i>
        <div class="warning-feedback">{{ "FORM.REQUEST.CITY" | translate }} {{ "FORM.REQUEST.IS_REQUIRED" | translate }}</div>
      </div>
    </div>
  </div>

  <select class="form-control mb-6" formControlName="country">
    <option selected disabled value>
      {{ "FORM.REQUEST.COUNTRY" | translate }}
    </option>
    <option *ngFor="let country of countries" [value]="country">
      {{ country }}
    </option>
  </select>

  <input type="text" class="form-control mb-6" formControlName="comment" placeholder="{{ 'FORM.REQUEST.COMMENT' | translate }}"/>

  <div class="float-end">
    <a class="btn btn-secondary btn-block me-4" href="/">{{ "COMMON.BACK" | translate }}</a>
    <button type="submit" class="btn btn-primary" [disabled]="!requestForm.valid">
      {{ "FORM.REQUEST.SEND_REQUEST" | translate }}
    </button>
  </div>
</form>


