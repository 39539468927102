<si-landing-page
  [heading]="settings.landingpageTitle"
  [subheading]="settings.landingpageSubtitle"
  [links]="links"
  copyright="© Siemens 2021-2024"
  [backgroundImageUrl]="backgroundImageUrl"
  [subtitle]="'APP.CLAIM' | translate" [availableLanguages]="langs">
  <router-outlet></router-outlet>
</si-landing-page>

<ng-template #corporateInfo>
  <div class="modal-header">
    <h4 class="modal-title">{{'APP.CORPORATE-INFORMATION' | translate}}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="closeDialog()">
      <si-icon icon="element-cancel"></si-icon>
    </button>
  </div>
  <div class="corporate-info-outlet modal-body" [ngSwitch]="translate.currentLang">
    <p *ngSwitchCase="'de'">
      <strong>Siemens Schweiz AG</strong><br>
      Smart Infrastructure, Global Headquarters<br>
      Theilerstrasse 1a<br>
      6300 Zug<br>
      Schweiz<br>
      <a href="mailto:web.sbt@siemens.com">web.sbt@siemens.com</a>
    </p>
    <p *ngSwitchDefault>
      <strong>Siemens Switzerland Ltd</strong><br>
      Smart Infrastructure, Global Headquarters<br>
      Theilerstrasse 1a<br>
      6300 Zug<br>
      Switzerland<br>
      <a href="mailto:web.sbt@siemens.com">web.sbt@siemens.com</a>
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary btn-md" (click)="closeDialog()">
      {{'COMMON.CLOSE-BUTTON' | translate}}
    </button>
  </div>
</ng-template>
