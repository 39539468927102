import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { ConfigService } from '../shared/config.service';

@Component({
  selector: 'app-request-demo-account-mask',
  templateUrl: './request-demo-account-mask.component.html'
})
export class RequestDemoAccountMaskComponent implements OnInit {
  countries = ['Germany', 'France', 'England', 'Spain'];

  get invalidName(): boolean {
    return this.requestForm.controls.companyName.invalid && this.requestForm.controls.companyName.touched;
  }

  get invalidStreet(): boolean {
    return this.requestForm.controls.street.invalid && this.requestForm.controls.street.touched;
  }

  get invalidPostCode(): boolean {
    return this.requestForm.controls.postcode.invalid && this.requestForm.controls.postcode.touched;
  }

  get invalidCity(): boolean {
    return this.requestForm.controls.city.invalid && this.requestForm.controls.city.touched;
  }

  requestForm = this.fb.group({
    companyName: ['', Validators.required],
    street: ['', [Validators.required, Validators.minLength(3)]],
    postcode: ['', [Validators.required, Validators.pattern('[0-9]{5}')]],
    city: ['', Validators.required],
    country: ['', Validators.required],
    comment: ['', Validators.maxLength(200)],
  });

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    public translate: TranslateService,
    private cookieService: CookieService,
    private configService: ConfigService
  ) {}

  ngOnInit(): void {
    const cookieName = this.configService.getConfig().authCookieName;
    const selectedLang = this.cookieService.get(cookieName);
    this.translate.use(selectedLang);
  }

  onSubmit(): void {
    if (this.requestForm.valid) {
      this.router.navigateByUrl('request-success');
    }
  }
}
