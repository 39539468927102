import { Inject, Injectable, InjectionToken, PLATFORM_ID } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';

export interface Theme {
  themeName: string;
}

@Injectable({ providedIn: 'root' })
export class ThemeRuntimeSwitchService {

  useSiemensBrandTheme = false;
  private isBrowser: boolean;

  constructor(
    @Inject(PLATFORM_ID) platformId: InjectionToken<any>,
    private httpClient: HttpClient) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  hasSiemensBrandToggleSwitch(): Observable<boolean> {
    if (location.href.includes('localhost')){
      return of(true);
    }

    const siemensBrandCheckUrl = '/shell/navigation/theme.json';
    return this.httpClient.get<Theme>(siemensBrandCheckUrl).pipe(
      map(theme => theme.themeName === 'siemens-brand'),
      catchError(() => of(false))
    );
  }

  switchToSiemensBrand(): void {
    if (!this.isBrowser) {
      return;
    }

    const classList = document.documentElement.classList;
    classList.forEach(c => {
      if (c.startsWith('theme-')) {
        classList.remove(c);
      }
    });
    classList.add(`theme-siemens-brand`);
  }
}
