<div>
  <form (ngSubmit)="onSubmit()">
    <div class="form-group mb-6">
      <label class="form-label" for="email" [hidden]="!customLogin">{{ "FORM.LOGIN.EMAIL" | translate }}</label>
      <!-- <input #email type="email" class="form-control" formControlName="email" [hidden]="!customLogin" (input)="onValueChanged($event)"/> -->
      <input #email type="email" class="form-control" [formControl]="emailControl" *ngIf="customLogin" />
    </div>
    <button #login id="login-button" type="submit" class="btn btn-primary" style="width: 100%"
      [disabled]="loginDisabled" autofocus>
      {{ "FORM.LOGIN.LOGIN" | translate }}
    </button>
  </form>
  <br>
  <div style="display: flex; justify-content: space-between;">
    <a id="custom-login-link" href="#" (click)="toggleCustomLogin()" [hidden]="customLogin">{{ "FORM.LOGIN.CUSTOM_LOGIN" | translate }}</a>
    <a id="default-login-link" href="#" (click)="toggleCustomLogin()" [hidden]="!customLogin">{{ "FORM.LOGIN.DEFAULT_LOGIN" | translate }}</a>
    <a id="request-demo-link" [href]="settings?.requestDemoUrl" [hidden]="!settings?.requestDemoUrl">{{
      "FORM.LOGIN.DEMO_ACCOUNT" | translate }}</a>
  </div>

  <p class="pt-6" style="color: #7d8099;">
    <strong translate>FORM.LOGIN.DISCLAIMER</strong>:<br/>
    <span translate>FORM.LOGIN.DISCLAIMER_TEXT</span>
  </p>
</div>
