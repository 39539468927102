import { APP_INITIALIZER, ErrorHandler, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { LoginMaskComponent } from './login-mask/login-mask.component';
import { RequestDemoAccountMaskComponent } from './request-demo-account-mask/request-demo-account-mask.component';
import { RequestSuccessComponent } from './request-success/request-success.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {
  SiIconModule,
  SiLocaleConfig,
  SiLocaleId,
  SiLocaleService,
  SiLocaleStore,
  SimplElementNgModule,
  SI_LOCALE_CONFIG,
  SiInlineNotificationModule
} from '@simpl/element-ng';
import { CookieService } from 'ngx-cookie-service';
import { SiAuthModule } from '@sisuite/auth';
import { ConfigService } from './shared/config.service';
import { LOCATION_INITIALIZED, registerLocaleData } from '@angular/common';
import { SiConfigProvider, SiErrorHandlerModule, SiShellLocaleStoreService, adaptLocale } from '@sisuite/ui';
import { GlobalErrorHandler } from './services/error-handler-service';
import { Router, RouterModule, Routes } from '@angular/router';
import * as Sentry from '@sentry/angular-ivy';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { SafePipe } from './shared/safe.pipe';
import { environment } from 'src/environments/environment';
import { SiTranslateNgxTModule } from '@simpl/element-ng/ngx-translate';
import { take } from 'rxjs/operators';

export function configurationFactory(configService: ConfigService): () => Promise<void> {
  return (): Promise<void> => configService.init();
}

export function createTranslateLoader(http: HttpClient): MultiTranslateHttpLoader {
  return new MultiTranslateHttpLoader(http, [
    { prefix: './assets/shared/i18n/', suffix: '.json' },
    { prefix: './assets/i18n/', suffix: '.json' }
  ]);
}

export function appInitializerFactory(localeService: SiLocaleService, injector: Injector): () => Promise<any> {
  return () =>
    new Promise<any>((resolve: any) => {
      const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
      locationInitialized.then(() => {
        localeService.locale$.pipe(take(1)).subscribe(
          () => undefined,
          (err: any) => console.error(err),
          () => resolve(null)
        );
      });
    });
}


const genericLocaleInitializer = (localeId: string): Promise<any> => {
  const adaptedLocaleId = adaptLocale(localeId);
  return import(
    /* webpackInclude: /(en|de|fr|es|cs|da|nl|fi|it|hu|nb|pl|pt|sk|sv|zh-Hans|zh-Hant|ko|ro|tr|el)\.mjs$/ */
    `@/../@angular/common/locales/${adaptedLocaleId}.mjs`
  ).then((module) => {
    registerLocaleData(module.default);
  }, (error) => {
    // webpackInclude: when angular doesn't support all the locales js
    console.error(error);
  });
};

const localeConfig: SiLocaleConfig = {
  availableLocales: ['en','de','fr','es','cs','da','nl','fi','it','hu','nb-NO','pl','pt','sk','sv','zh-Hans','zh-Hant','ko','ro','tr','el'],
  defaultLocale: 'template',
  localeInitializer: genericLocaleInitializer,
  dynamicLanguageChange: false,
  fallbackEnabled: true
};

const routes: Routes = [
  {
    path: 'request-demo-account',
    component: RequestDemoAccountMaskComponent,
    data: { headerTitle: 'Request Demo Account' },
  },
  {
    path: 'request-success',
    component: RequestSuccessComponent,
    data: { headerTitle: 'Request success' },
  },
  {
    path: '',
    component: LoginMaskComponent,
    data: { headerTitle: 'Security Manager' },
  },
  {
    path: '**',
    redirectTo: '/',
    pathMatch: 'full',
  }
];

@NgModule({
  declarations: [
    AppComponent,
    LoginMaskComponent,
    RequestDemoAccountMaskComponent,
    RequestSuccessComponent,
    SafePipe
  ],
  imports: [
    HttpClientModule,
    BrowserAnimationsModule,
    BrowserModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    SimplElementNgModule,
    RouterModule.forRoot(routes, {
      useHash: true,
      // disable overriding of the auth code in the url.
      // see https://manfredsteyer.github.io/angular-oauth2-oidc/docs/additional-documentation/routing-with-the-hashstrategy.html
      initialNavigation: 'disabled',
    }),
    ReactiveFormsModule,
    FormsModule,
    ReactiveFormsModule,
    SiAuthModule.forRoot(),
    SiIconModule,
    SiErrorHandlerModule,
    SiInlineNotificationModule,
    SiTranslateNgxTModule
  ],
  providers: [
    Title,
    HttpClientModule,
    CookieService,
    ConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [SiLocaleService, Injector],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: configurationFactory,
      deps: [ConfigService],
      multi: true
    },
    { provide: LOCALE_ID, useClass: SiLocaleId, deps: [SiLocaleService] },
    { provide: SI_LOCALE_CONFIG, useValue: localeConfig },
    { provide: SiLocaleStore, useClass: SiShellLocaleStoreService },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: SiConfigProvider, useExisting: ConfigService },
    { provide: Sentry.TraceService, deps: [Router] },
    { provide: 'environment', useValue: environment },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
